.contact {
    padding: 4rem;
    text-align: center;
  }
  
  h1 {
    text-align: center;
    font-size: 70px;
    color: rgba(255,255,255,0.8);
  }
  .contact> p {
    margin-top: 40px;
    margin-bottom: 10px;
    color: rgba(255,255,255,0.8);
    font-size: 26px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
      line-height: 1.4;
  }
  form { 
    max-width:420px; margin:50px auto; 
  }

.feedback-input {
    color:rgba(255,255,255,0.8);
    font-family: Helvetica, Arial, sans-serif;
    font-weight:500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    border:2px solid rgba(255,255,255,0.8);
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width:100%;
    box-sizing: border-box;
    outline:0;
}

.feedback-input:focus { 
  border:2px solid rgba(255,255,255,0.8); 
}

textarea {
  height: 150px;
  line-height: 150%;
  resize:vertical;
}

[type="submit"] {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background:rgba(255,255,255,0.8);
  border-radius:5px;
  border:0;
  cursor:pointer;
  color:#2b2b2b;
  font-size: 1.2rem;
  padding-top:10px;
  padding-bottom:10px;
  transition: all 0.3s;
  margin-top:-4px;
  font-weight:700;
}
[type="submit"]:hover { 
  background:#2b2b2b;
  border: 1px solid rgba(255,255,255,0.8);
  color: rgba(255,255,255,0.8);
}

.contact--icons {
  color: rgba(255,255,255,0.8);;
  font-size: 4rem;
  margin: 10px;
  margin-bottom: -30px;
}

.g-recaptcha {
  display: inline-block;
  margin-bottom: 30px;
  margin-top: 4px;
}

@media screen and (max-width: 960px) {
  h1 {
    font-size: 50px;
  }
  .contact p {
      font-size: 22px;
  }
  .contact--icons {
    font-size: 3rem;
  }
  .contact {
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 50px;
  }
  .contact p {
      font-size: 20px;
  }
  .contact--icons {
    font-size: 2.5rem;
  }
}




