.about {
    padding: 4rem;
    text-align: center;
  }

  .JM--icon {
    border-radius: 50%;
    margin-top: 18px;
  }
  
  h1 {
    text-align: center;
    font-size: 70px;
    color: rgba(255,255,255,0.8);
  }
  .about> p {
    margin-top: 30px;
    margin-bottom: 10px;
    color: rgba(255,255,255,0.8);
    font-size: 26px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
      line-height: 1.4;
      text-align: center;
  }
  .list--wrapper {
    text-align: left;
    display: inline-block;
    color: rgba(255,255,255,0.8);
    font-size: 26px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
      line-height: 1.4;
  }

  .about--icons {
    color: rgba(255,255,255,0.8);
    font-size: 4rem;
    margin: 10px;
    margin-top: 20px;
  }
  .about--icon--wrapper {
    text-align: center;
  }

  @media screen and (max-width: 960px) {
    h1 {
      font-size: 50px;
    }
    .about p {
        font-size: 22px;
    }
    .list--wrapper {
      font-size: 22;
    }
    .about--icons {
      font-size: 3rem;
    }
    .JM--icon {
      width: 120px;
      height: auto;
    }
    .about {
      padding: 2rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 40px;
    }
    .about p {
        font-size: 20px;
    }
    .about--icons {
      font-size: 2.5rem;
    }
    .list--wrapper {
      font-size: 20;
    }
    .JM--icon {
      width: 100px;
      height: auto;
    }
}





