:root {
    --primary:rgba(255,255,255,0.8);
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    letter-spacing: 1px;
}

.btn--outline {
    background-color: transparent;
    color: rgba(255,255,255,0.8);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3 ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--hero {
    padding: 12px 26px;
    font-size: 1.2rem;
}

.btn--medium:hover, .btn--large:hover {
    background-color: rgba(255,255,255,0.8);
    color: #242424;
    transition: all 0.3 ease-out;
}

.btn--hero:hover {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    color: #fff;
    transition: all 0.3 ease-out;
}